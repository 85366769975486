import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { Nav } from 'components/nav'
import { MeetUsMenu } from 'components/pages/MeetUsPage'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const EventsPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO title="Meet Us - Events" pageUrl="/meet-us/events/" description="Join us in our out-of-office activities. You'll love it." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 py-6">
        <HeaderLink className="text-primary-2" />
        <Link to="/meet-us/" className="block pt-12 lg:py-12 text-left">
          <h1 className="pt-4 pb-4 text-4xl lg:text-5xl text-shadow">Meet us</h1>
        </Link>
      </Header>
      <Section className="z-20" gridRow={2}>
        <div className="grid grid-cols-22ch-auto row-gap-6" style={{ gridTemplateRows: 'min-content 1fr' }}>
          <h1 className="hidden lg:block lg:row-start-1 text-7xl lg:text-8xl text-right">&nbsp;</h1>
          <MeetUsMenu className="col-span-2 lg:col-span-1 row-start-1 lg:row-start-2" />
          <div className="col-span-2 lg:col-span-1 row-start-3 lg:row-start-2 text-right text-primary-2">
            <a href="https://www.meetup.com/Swift-Meetup-Sofia/" target="_blank" rel="noreferrer">
              <h2 className="text-3xl lg:text-4xl">#SwiftSofia</h2>
              <p className="text-xl lg:text-2xl font-normal tracking-normal">Hands-on meetups on Swift & iOS</p>
            </a>
            <a href="https://www.meetup.com/AndroidSofia/" target="_blank" rel="noreferrer">
              <h2 className="text-3xl lg:text-4xl">#AndroidSofia</h2>
              <p className="text-xl lg:text-2xl font-normal tracking-normal">Talk about Android mobile development</p>
            </a>
            <a href="https://www.spodari.org/" target="_blank" rel="noreferrer">
              <h2 className="text-3xl lg:text-4xl">#SpoDari</h2>
              <p className="text-xl lg:text-2xl font-normal tracking-normal">Do good sport initiatives</p>
            </a>
          </div>
        </div>
      </Section>
      <BackgroundWithGrid image="meet-us-events" gridRow="1/4" />
      <div className="z-0 bg-primary-1" style={{ gridColumn: '1 / -1', gridRow: '1 / 4', opacity: 0.8 }} />
      <div className="z-0 h-16" style={{ gridRow: 3, gridColumn: '1/-1', background: 'linear-gradient(#00edae00, #00edae)' }} />
      <Nav className="z-20 text-primary-2" gridRow={4} />
    </MainWithMenu>
  </Layout>
)

export default EventsPage
